import { pipe, prop } from 'ramda';
import { map } from 'rxjs/operators';

// Taken from: https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer/21797381
export const convertBase64ToArrayBuffer = (base64: string) => {
  const binary = window.atob(base64);
  const len = binary.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes.buffer;
};

export const convertToArrayBuffer = map(
  pipe(prop('pdfImage'), convertBase64ToArrayBuffer),
);
